@import "framework";

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=9ac5b212-8677-477e-a04c-d741eb82e874");
@font-face{
  font-family:"Neue Haas Grotesk Disp W05_55R";
  src:url("/fonts/3694027c-8b12-43e1-b2c0-1287ee512fe3.woff2") format("woff2"),url("/fonts/b7134925-c4a5-4a09-8c31-42f0e33a1324.woff") format("woff");
}
@font-face{
  font-family:"Plantin MT W01 Light";
  src:url("/fonts/94834402-7ccb-47a0-a20f-28759066e23b.woff2") format("woff2"),url("/fonts/50d3e854-4121-44aa-b019-bd0d8a758cb3.woff") format("woff");
}
@font-face{
  font-family:"Plantin MT W01 Regular";
  src:url("/fonts/47a817c1-92a4-4d89-9196-59224b6a42a0.woff2") format("woff2"),url("/fonts/83607231-52ee-4f36-9fb0-beeb990a951c.woff") format("woff");
}

// Vars

$body-size: 16px;
$ref-width: 1440px;
$body-color: #0000ff;
$background-color: #ffffff;
$body-font-family: "Neue Haas Grotesk Disp W05_55R", sans-serif;

$mobile: (body-size: 16px, ref-width: 800px, breakpoint: 600px);

// Global

body {
  background-color: $background-color;
  color: $body-color;
  font-family: $body-font-family;
  @include set-body-size;
  letter-spacing: 0.075em;
  @include mobile {
    @include set-body-size;
  }
}

h1, h2 {
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
}

a {
  text-decoration: none;
}

#app > header {
  position: fixed;
  left: 0;
  top: em(150px);
  width: 100%;
  h1 {
    color: #000000;
    text-align: center;
    font-size: em(90px);
    line-height: 1;
    letter-spacing: 0.075em;
    cursor: pointer;
  }
}

#app > footer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

#app > .content {
  text-align: center;
  padding: em(70px) 0;
  @include mobile {
    padding: 0;
  }
  &.noScroll {
    overflow: hidden;
  }
}

#app > footer {
  text-align: center;
  cursor: pointer;
  display: grid;
  .sections { 
    padding: em(70px) 0;
    margin: auto;
    section {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      @include mobile {
        width: 80%;
      }
      h2 {
        font-size: 1em;
        line-height: 1.285714;
        letter-spacing: 0.075em;
      }
      > div, > ol > li {
        font-family: "Plantin MT W01 Light", serif;
        font-size: em(32px);
        line-height: 1.3125;
        letter-spacing: 0.050em;
      }
      > ol > li {
        display: inline;
        & + li::before {
          content: ', ';
        }
      }
      & + section {
        margin-top: em(100px);
      }
    }
  }
}

// Layers

#app {
  > header { z-index: 5; }
  > footer { z-index: 4; }
  > .content { z-index: 0; }
}

// Grid

@each $size in 10, 20, 25, 33, 46, 58, 100 {
  .width-#{$size} {
    width: ($size * 1%); 
    width: ($size * 1vw);
    @include mobile {
      width: 100%; width: 100vw;
    }
  }
}

.center {
  margin-left: auto;
  margin-right: auto;
}
.right {
  margin-left: auto;
  margin-right: 0;
}
@each $offset in 10, 20, 25, 30, 33, 40, 50, 60, 66, 70, 75  {
  .left-#{$offset} {
    margin-left: ($offset * 1%);
    margin-right: auto;
    @include mobile {
      margin-left: auto;
    }
  }
}

@each $overlap in 0, 5, 10, 15, 20, 25, 30  {
  .overlap-bottom-#{$overlap} {
    margin-bottom: ($overlap * -1%); 
    @include mobile {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .push-bottom-#{$overlap} {
    margin-bottom: ($overlap * 1%); 
    @include mobile {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Transitions

[v-cloak] { display: none; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

